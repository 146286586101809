.footer {
    background-color: #1d1d1d;
    padding: 40px 20px;
}

.footer-appname {
    text-shadow: 0 3px 5px rgba(101, 79, 226, 0.389);
}

.link-text {
    font-weight: lighter !important;
    color: gainsboro !important;
}

.link-text:hover {
    font-weight: lighter !important;
    color: white !important;
}