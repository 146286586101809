/* Task Management Button */

.task-management-btn {
    padding: 13px 16px;
    position: fixed;
    right: 50px;
    bottom: 50px;
    background-color: darkblue;
    color: white;
    font-size: 20px;
    border-radius: 50%;
    text-align: center
}

.task-management-btn:hover {
    box-shadow: 0 0 5px gray;
}

.task-management-btn:active {
    background-color: gray;
    color: black;
}

@media (max-width:1000px) {
    .task-management-btn {
        bottom: 20px;
        right: 20px;
    }
}


/* landing one */

.landing-one {
    min-height: 100vh;
    height: 100vh;
    background: #09003D;
}

.landing-two {
    min-height: 100vh;
    width: 100%;
    background: linear-gradient(to bottom, #09003D, #142974);
}

.landing-three {
    min-height: 100vh;
    width: 100%;
    background: linear-gradient(to bottom, #142974, #09003D);
}

.bulb {
    transition: 0.5s;
    --webkit-filter: drop-shadow(0 0 5px goldenrod);
    filter: drop-shadow(0 0 5px goldenrod);
}

.bulb:hover {
    transform: scale(1.1);
}