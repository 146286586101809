.panel-sidebar {
    min-width: 200px;
    height: 100vh;
    position: sticky !important;
    top: 55px !important;
    left: 0 !important;
    padding: 10px 0px;
    margin: 0;
    transition: 0.3s;
}

.hide-panel {
    position: fixed !important;
    left: -200px !important;
}

.show-panel {
    position: sticky !important;
    left: 0;
}

.panel-menu-item {
    padding: 10px 12px;
    transition: 0.3s;
    cursor: pointer;
}

.panel-menu-item:hover {
    background-color: #f1f1f1;
}

.active-item {
    background-color: #e5e5e5;
    border-left: 3px solid var(--primary);
    color: var(--primary);
}

.task-management-btn {
    padding: 13px 16px;
    position: fixed;
    right: 20px;
    bottom: 30px;
    background-color: darkblue;
    color: white;
    font-size: 20px;
    border-radius: 50%;
    text-align: center
}

.task-management-btn:hover {
    box-shadow: 0 0 5px gray;
}

.task-management-btn:active {
    background-color: gray;
    color: black;
}