    @import "./bootstrap.css";
    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box !important;
        font-family: "Source Sans 3", sans-serif;
        text-decoration: none !important;
        list-style-type: none;
    }
    
    pre {
        font-family: "Source Sans 3", sans-serif;
        font-size: 1em;
    }
    
     :root {
        --primary: #00008b;
    }
    
    .gradient-text {
        background-image: linear-gradient(to right, yellow, green);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
    }
    /* Test Button */
    
    .my-button {
        background-color: darkblue;
        border-radius: 10px;
        padding: 5px 20px;
        border: 1px solid rgba(0, 0, 0, 0.5);
        color: lightslategray;
        box-shadow: 0 0 5px gray;
        transition: 0.3s ease-in-out;
    }
    
    .my-button:hover {
        background-color: rgb(0, 0, 124);
        /* box-shadow: 0 0 7px gray; */
    }
    
    .my-button:active {
        background-color: darkblue;
        box-shadow: 0 0 5px gray;
        transform: scale(0.96);
    }
    
    @media (max-width:345px) {
        .my-button {
            width: 100%;
        }
    }
    /* Toast Widget */
    
    .toast-widget {
        background-color: #1d1d1d;
        padding: 5px 20px;
        color: white;
        border-radius: 8px;
        box-shadow: 0 0 10px gray;
        right: 30px;
        top: 30px;
        position: fixed;
        transform: scale(0);
        transform-origin: 100% 50%;
        z-index: 2000;
    }
    
    .show-toast {
        animation: showToastAnimation 0.5s ease-in-out forwards;
    }
    
    @keyframes showToastAnimation {
        from {
            transform: scale(0);
        }
        to {
            transform: scale(1);
        }
    }
    
    .nav-bar-blur {
        background-color: rgba(255, 255, 255, .1);
        backdrop-filter: blur(100px);
        border-bottom: 1px solid rgba(255, 255, 255, 0.05) !important;
        color: gainsboro !important;
    }
    
    .init-slide-up-animation {
        opacity: 0;
        animation: initSlideUpAnimation 1s 1s ease-in-out forwards running;
    }
    
    .init-slide-left-animation {
        opacity: 0;
        animation: initSlideLeftAnimation 1s 0s ease-in-out forwards running;
    }
    
    .init-slide-right-animation {
        opacity: 0;
        animation: initSlideRightAnimation 1s 0s ease-in-out forwards running;
    }
    
    .init-slide-top-animation {
        opacity: 0;
        animation: initSlideTopAnimation 1s 0s ease-in-out forwards running;
    }
    
    .animation-delay-1 {
        animation-delay: 1s;
    }
    
    .animation-delay-2 {
        animation-delay: 2s;
    }
    
    .animation-delay-3 {
        animation-delay: 3s;
    }
    
    .animation-delay-4 {
        animation-delay: 4s;
    }
    
    @keyframes initSlideUpAnimation {
        from {
            opacity: 0;
            transform: translateY(100%);
        }
        to {
            opacity: 1;
            text-replace: translateY(0);
        }
    }
    
    @keyframes initSlideLeftAnimation {
        from {
            opacity: 0;
            transform: translateX(100%);
        }
        to {
            opacity: 1;
            text-replace: translateX(0);
        }
    }
    
    @keyframes initSlideRightAnimation {
        from {
            opacity: 0;
            transform: translateX(-100%);
        }
        to {
            opacity: 1;
            text-replace: translateX(0);
        }
    }
    
    @keyframes initSlideTopAnimation {
        from {
            opacity: 0;
            transform: translateY(-100%);
        }
        to {
            opacity: 1;
            text-replace: translateY(0);
        }
    }