.printer-card {
    cursor: pointer;
    transition: 0.2s;
}

.printer-card:hover {
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    transform: translateY(-10px) scale(1.01);
    border-radius: 10px;
}

.printer-card:active {
    box-shadow: 0 10px 10px transparent;
}